@import url('https://fonts.googleapis.com/css?family=Paytone+One&display=swap');
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Courgette&display=swap');

/* colors */
$primaryColor: #352793;
$secondaryColor: #1D4AD1;
$thirdColor: #130f40;
$forthColor: #0c2461;

/* fonts */
$primaryFont: 'Paytone One',
sans-serif;
$secondaryFont: 'Nunito', sans-serif;
$thirdFont: 'Courgette', cursive;

/* sizes */
$titleFontSize: 62px;

* {
    margin: 0;
    padding: 0;
  }

.App {
    text-align: center;
    overflow-x: hidden;
}

/* canvas */
.wrapper{
    position: relative;
    display: inline-block;
}

.canvasText{
    font-family: $primaryFont;
    line-height: 130%;
    position: absolute;
    color: #ffffff;
    right: 3%;
    top: 40%;
    width: 100%;
    padding-right: 10%;
    padding-left: 10%;
}

canvas{
    position: relative;
    z-index: -1;
}

// Scroll Button

#scroll{
    top: calc( 50vh - 50px );
}

.scroll #button {
    background-color: transparent;
    border: none;
    position: absolute;
    left: 50%;
    outline: none;
    color: #fff;
    font : normal 400 1.8vw $secondaryFont, sans-serif;
    letter-spacing: .1em;
    text-decoration: none;
    transition: opacity .3s;
}
  
.scroll #button:hover {
    opacity: .5;
}
  
#scrollButton #button {
    padding-top: 50px;
}
#scrollButton #button span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 22px;
    height: 22px;
    margin-left: -11px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
  
  
/* global classes */

.title-font {
    font-family: $primaryFont;
    line-height: 130%;
}

.title-font-2 {
    font-family: $secondaryFont;
    line-height: 130%;
}

.title-font-3 {
    font-family: $thirdFont;
    line-height: 130%;
}


.title-font-size {
    font-size: $titleFontSize;
}

.section-title {
    padding-top: 7%;
}



/* --------------------- Banner --------------------- */

.banner {
    color: white;
    padding-top: 40px !important;
}

.banner-image-mobile {
    display: none;
}

.banner-image {
    width: 90%;
}

.banner-text {
    margin-bottom: 30px;
}

.banner-text h1 {
    font-size: 62px;
}

.banner-text .text-yellow {
    color: #7ced03;
}

.text-highlight {
    color: rgb(159, 225, 255) !important;
}

/*----------------------About us area--------------------------*/

.about-us-area {
    background: $thirdColor no-repeat;
    background-image: url('./img/shape2.png');
    background-position: center;
    background-size: 400px;
    padding: 5%;

    .about-us-text-area {
        margin:0 auto;
        max-width:600px;
        padding: 3em;
        background: $forthColor;
        -webkit-box-shadow: 0px 0px 46px -17px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 46px -17px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 46px -17px rgba(0,0,0,0.75);

        h2 {
            font-weight: 900;
        }
    }

}

/*---------------------- portfolio area --------------------------*/

.portfolio {
    padding: 5%;

    .portfolio-title {
        padding: 0px 0px 40px 0px;

        h1 {
            font-size: $titleFontSize;
        }

        p {
            font-size: 1.6rem;
        }
    }

    .portfolio-thumb {
        margin: 0 auto;
        margin-bottom: 9%;

        /* width: 100%;
        height: 100%; */
        float: left;
        overflow: hidden;
        position: relative;
        text-align: center;
        cursor: default;
        
 
        img {
            width: 100%;
            transition: transform .25s, visibility .25s ease-in;
        }
        :hover img {
            transform: scale(1.5);
        }
        .image-container {
            .hover-content {
                width: 100% !important;
                height: 100% !important;
                background: $primaryColor;
            }
            .title {
                color: white;
                opacity: 1;
                margin-top: 40%;
                font-weight: 900;
                font-size: 30px;
                font-family: $secondaryFont;
            }
            .hover-content.fadeIn {
                opacity: 0.9;
            }
        }
    }
}


/*---------------------- lead area --------------------------*/

.lead {
    background: $thirdColor;

    h1 {
        font-size: $titleFontSize;
    }

    button {
        border-radius: 7px 7px 7px 7px;
        -moz-border-radius: 7px 7px 7px 7px;
        -webkit-border-radius: 7px 7px 7px 7px;
        border: 0px solid #000000;
        height: 50px;
        width: 50%;
        background: $primaryColor;
        font-family: $secondaryFont;
        font-weight: 900;
        font-size: 100%;

        :hover {
            text-decoration: none;
        }

        a {
            color: #ffff;
        }
    }
}


.home-about-social {
    text-align: center !important;
    padding-top: 25px;
    color: white !important;
  }
  
  .home-about-social-links {
    justify-content: center !important;
    padding-top: 15px !important;
    display: inline-block !important;
    position: relative !important;
    padding-inline-start: 0 !important;
  }
  
  .home-social-icons {
    position: relative !important;
    display: inline-block !important;
    width: 40px !important;
    height: 40px !important;
    text-align: center !important;
    font-size: 1.2em !important;
    line-height: 2em !important;
    background: rgba(255, 255, 255, 0.972) !important;
    border-radius: 50% !important;
    transition: 0.5s !important;
  }
  
  .home-social-icons::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #68187a;
    transition: 0.5s;
    transform: scale(0.9);
    z-index: -1;
  }
  
  .home-social-icons:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 15px #801f95;
  }
  
  .home-social-icons:hover {
    color: #87209e;
    box-shadow: 0 0 5px #87209e;
    text-shadow: 0 0 2px #87209e;
  }
  
  .social-icons {
    display: inline-block !important;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .icon-colour {
    color: #700c86 !important;
  }

/*---------------------- footer area --------------------------*/
footer {
    background: $thirdColor;
    bottom: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 8px !important ;
}

/*---------------------------------------------------------------*/
/*---------------------- contact page ---------------------------*/
/*---------------------------------------------------------------*/

.contact {

    p {
        font-size: 1.6rem;
    }

    .contact-form {
        padding-bottom: 10%;

        form-group:focus {
            outline: none;
        }

        input, textarea {
            background: transparent;
            font-family: $secondaryFont;
            border-bottom: 1px solid white;
            border-top: none;
            border-left: none;
            border-right: none;
            border-radius: 0;
            padding: 4%;
            color: white;
        }

        input::placeholder, textarea::placeholder {
            color: white;
            opacity: 0.5;
        }

        input:focus, textarea:focus {
            outline: none;
            box-shadow: none;
        }

        button {
            border-radius: 7px 7px 7px 7px;
            -moz-border-radius: 7px 7px 7px 7px;
            -webkit-border-radius: 7px 7px 7px 7px;
            border: 0px solid #000000;
            height: 50px;
            width: 50%;
            background: $secondaryColor;
            font-family: $secondaryFont;
            color: white;
            font-weight: 900;
            font-size: 100%;
    
            :hover {
                text-decoration: none;
            }

            :focus {
                outline: white;
                box-shadow: white;
            }
        }
    }
}

/* --------- */
/* Projects */
/* --------- */
.project-section {
    position: relative !important;
    padding-top: 100px !important;
    padding-bottom: 30px !important;
    background-image: var(--section-background-color) !important;
  }
  
  .project-card {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    height: auto !important;
  }
  
  .project-card-view {
    box-shadow: 0 4px 5px 3px rgba(255,255,255, 0.459) !important;
    color: white !important;
    background-color: transparent !important;
    transition: all 0.5s ease 0s !important;
    height: 100% !important;
  }
  .project-card-view:hover {
    transform: scale(1.02) !important;
    overflow: hidden !important;
    box-shadow: 0 4px 4px 5px rgba(255,255,255, 0.561) !important;
  }
  
  .blog-card {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    height: auto !important;
  }
  
  .blog-card-view {
    background-color: transparent !important;
    box-shadow: 0 3px 3px 2px rgba(145, 77, 161, 0.459) !important;
    color: white !important;
    transition: all 0.5s ease 0s !important;
    height: 100% !important;
  }
  
  .blog-link {
    color: white !important;
    text-decoration: none !important;
  }
  
  .blog-link:hover {
    cursor: pointer !important;
  }
  
  .blog-card-view:hover {
    transform: scale(1.02) !important;
    overflow: hidden !important;
    box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) !important;
  }
  
  .card-img-top {
    padding: 20px !important;
    opacity: 0.8 !important;
    border-radius: 10px !important;
  }
  
  .blog-img {
    padding: 0px !important;
    opacity: 0.8 !important;
    border-radius: 0px !important;
  }
  
  .btn-primary {
    color: #fff !important;
    background-color: #623686 !important;
    border-color: #623686 !important;
  }
  
  .btn-primary:hover {
    color: #fff !important;
    background-color: #6d20c5d7 !important;
    border-color: #6d20c5d7 !important;
  }
  .btn:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  .project-heading {
    color: white !important;
    font-size: 2.3em !important;
    font-weight: 500 !important;
    padding-top: 10px !important;
  }
  


/*---------------------------------------------------------------*/
/*---------------------- services page ---------------------------*/
/*---------------------------------------------------------------*/

.services {
    .services-title {
        padding-bottom: 5%;
    }
    .title-text {
        font-size: 1.6rem;
    }

    .service-name {
        font-weight: 900;
        font-size: 25px;
    }

    .service-boxes {
        padding: 10%;
        .service-icon {
            position: absolute;
            right: 90%;
        }

        img {
            width: 90px;
        }

        .service-info-title {
            height: 25px;
            margin-bottom: 5%;
        }

        .service-description {
            font-size: 20px;
        }
    }
}

/*---------------------------------------------------------------*/
    /*--------------- portfolio detail page --------------*/
/*---------------------------------------------------------------*/

.detail {
    .services-title {
        padding-bottom: 5%;
    }
    .detail-image {
        width: 100%;
    }

    .detail-info {
        .work-title {
            font-weight: 900;
        }

        .description {
            line-height: 1.6rem;
            font-size: larger;
        }
        
        hr {
            border-color:white; border-style:solid
        }
    }
}

/*---------------------------------------------------------------*/
    /*--------------- team page --------------*/
/*---------------------------------------------------------------*/

.team {

    margin-bottom: 10%;

    .portfolio-thumb {
        margin: 0 auto;
        margin-bottom: 5%;

        /* width: 100%;
        height: 100%; */
        float: left;
        overflow: hidden;
        position: relative;
        text-align: center;
        cursor: default;

        img {
            width: 100%;
            transition: transform .25s, visibility .25s ease-in;
        }
        :hover img {
            transform: scale(1.5);
        }

        .image-container {
            .hover-content {
                width: 100% !important;
                height: 100% !important;
                background: $primaryColor;
            }
            .title {
                color: white;
                opacity: 1;
                margin-top: 30%;
                font-weight: 900;
                font-size: 30px;
                font-family: $secondaryFont;
            }
            .hover-content.fadeIn {
                opacity: 0.9;
            }
        }
    }

    p {
        font-size: 1.6rem;
    }

    .person-info {
        visibility: visible;
        display: none;
    } 
}



.skills {
  margin-top: 30px;
}

.skills .container-content {
  margin:0 auto;
  max-width:800px;
  width: 100%;
}
.inner-skill-container {
  width: 100%;
  max-width: 800px;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  align-items: center;
}

.skills div img {
  height: 60px;
  margin-bottom: 25px;
  margin-top: 15px;
  transition: 0.5s;
  cursor: pointer;
}

.skills div img:hover {
  transform: scale(1.17);
}

.half-radius {
  border-radius: 50%;
}

/* for mobile phones (portrait) */
@media (min-width: 320px) and (max-width: 480px) {

    /* global styles */
    .section-title {
        padding-top: 0%;
    }
    /* global styles end */

    .title-font-size {
        font-size: -webkit-xxx-large;
    }

    .banner {
        padding: 0px 0 180px;
    }

    .banner-image-desktop {
        display: none;
    }

    .banner-image-mobile {
        display: block;
    }

    .banner-text {
        padding-top: 10%;
    }

    .banner-text h1 {
        font-size: 48px;
        margin-bottom: 8%;
        padding: 4%;
    }

    .banner-image {
        width: 90%;
    }

    /*----------------------About us area--------------------------*/
    
    .about-us-area {
        background-size: 400px;
        .about-us-text-area {
            h2 {
                font-size: 25px;
            }
        }
    }

    /*----------------------portfolio area--------------------------*/
    .portfolio {
        .portfolio-title {
            padding: 0;
            padding-top: 5%;

            h1 {
                font-size: -webkit-xxx-large;
            }
        }
    }

    /*----------------------lead area--------------------------*/
    .lead {
        h1 {
            font-size: -webkit-xxx-large;
        }
    }
    /*----------------------contact page--------------------------*/    

    .contact {
        p {
            font-size: 1.6rem;
        }
    }
    /*----------------------services page--------------------------*/    

    .services {
        .services-title {
            padding-bottom: 0;
        }
        .service-boxes {
            .service-icon {
                position: unset;
            }

            .service-info-title {
                height: 50px;
                margin-bottom: 5%;

                .service-name {
                    font-size: 20px;
                }
            }

            .service-description {
                text-align: center !important;
                font-size: 15px;
            }
    
        }
        .title-text {
            font-size: 1.6rem;
        }
    }
    /*----------------------team page--------------------------*/    

    .team {
        
        .person-info {
            visibility: visible;
            display: inline;

            .person-position {
                font-size: 15px;
                margin-bottom: 15%;
            }
        }
    }
    .skills div img {
        height: 40px;
      }
}


/* for desktop */
@media (min-width: 992px) {
    .banner {
        
        padding: 190px 0 125px;

        .banner-text {
            padding-top: 39%;
        }
    }
}

/* for mobile phones (landscape) */
/* iphone X */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation : landscape) {

    .banner {
        padding: 0px 0px 100px !important;

        .banner-text {
            padding-top: 10% !important;
            
            h1 {
                font-size: 48px;
                text-align: center;
            }
        }

        .banner-image {
            width: 23% !important;
            margin-top: 30px;
        }
    }

    .portfolio {
        .portfolio-title {
            padding: 0;
        }
    }
    
    .services {
        .services-title {
            padding-bottom: 0;
        }
        .service-boxes {
            .service-icon {
                position: unset !important;
            }

            .service-description {
                text-align: center !important;
            }
        }
    }

    .team {
        .person-info {
            visibility: visible;
            display: inline;

            .person-position {
                font-size: 15px;
                margin-bottom: 15%;
            }
        }
    }

    /* global styles */
    .section-title {
        padding-top: 0%;
    }
    
  }

  /* for mobile phones (landscape) */
  /* iphone 6/7/8 */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation : landscape) {

    .banner {
        padding: 0px !important;
        .banner-text {
            padding-top: 10%;

            h1 {
                font-size: 48px;
                text-align: center;
            }
        }
        .banner-image {
        width: 50%;
        margin-top: 30px;
        }
    }

    .portfolio {
        .portfolio-title {
            padding: 0;
        }
    }

    .team {

        padding-top: 0%;

        .person-info {
            visibility: visible;
            display: inline;

            .person-position {
                font-size: 15px;
                margin-bottom: 15%;
            }
        }
    }
  }

  /* pixel 2 xl (landscape) */
  @media only screen and (min-width: 411px) and (max-width: 767px) {
    .team {
        padding-top: 0%;

        .team-title {
            

            h1 {
                font-size: 75px;
            }
        }
        .person-info {
            visibility: visible;
            display: inline;

            .person-position {
                font-size: 15px;
                margin-bottom: 15%;
            }
        }
    }
  }

  /* pixel 2 */
  @media only screen and (min-width: 411px) and (max-width: 767px) {
    .team {
        padding-top: 0%;

        .team-title {
            margin-top: 0rem ;

            h1 {
                font-size: 75px;
            }
        }
        .person-info {
            visibility: visible;
            display: inline;

            .person-position {
                font-size: 15px;
                margin-bottom: 15%;
            }
        }
    }
  }
  /* tablets (portrait) */
  @media (min-width: 768px) and (max-width: 1024px) {

    .banner {
        padding: 0px 0px 100px;
        .banner-text {
            padding-top: 10%;

            h1 {
                font-size: 48px;
                text-align: center;
            }
        }
        .banner-image {
            width: 50%;
            margin-top: 30px;
            }
    }
    .contact {
        padding-bottom: 10%;

        .contact-form {
            padding-bottom: 30%;
        }
    }

    .services {
        .service-boxes {
            .service-icon {
                position: unset !important;
            }

            .service-description {
                text-align: center !important;
            }
        }
    }

    .team {
        padding-top: 0%;

        .team-title {
            h1 {
                font-size: 75px;
            }
        }
        .person-info {
            visibility: visible;
            display: inline;

            .person-position {
                font-size: 15px;
                margin-bottom: 15%;
            }
        }
    }
    
  }

  /* tablets (landscape) */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    .banner {
        padding: 10%;

        .banner-text {
            padding-top: 20%;
        }

        .banner-image {
            width: 80%;
        }
    }


    .team {
        padding-top: 10%;

        .team-title {
            h1 {
                font-size: 75px;
            }
        }
        .person-info {
            visibility: visible;
            display: inline;

            .person-position {
                font-size: 15px;
                margin-bottom: 15%;
            }
        }
    }
    
  }

    /* iPad Pro (Portrait and Landscape) */
    @media only screen 
    and (min-width: 1024px) 
    and (max-height: 1366px) 
    and (-webkit-min-device-pixel-ratio: 1.5) {

        .banner {
            padding: 0%;
    
            .banner-text {
                padding-top: 0%;
            }
    
            .banner-image {
                width: 80%;
            }
        }

        .team {
            padding-top: 10%;
    
            
            .person-info {
                visibility: visible;
                display: inline;
    
                .person-position {
                    font-size: 15px;
                    margin-bottom: 15%;
                }
            }
        }
    }